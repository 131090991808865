import i18n from 'i18next'
import React, { useEffect } from 'react'
import { ContentContainer } from '../components'

import { PageContainer, PageContainerOuter } from '../components/organisms'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { SupportPageDe, SupportPageEn } from './../content/support'

const SupportPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params
  const [loaded, setLoaded] = React.useState(false)

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  const origin = typeof window !== 'undefined' ? document.location.origin : ''

  return (
    <PageFrame {...params} pageName="support">
      <PageContainerOuter>
        <PageContainer>
          {lang === 'de' ? (
            <SupportPageDe origin={origin} />
          ) : (
            <SupportPageEn origin={origin} />
          )}
        </PageContainer>
      </PageContainerOuter>
    </PageFrame>
  )
}

export default SupportPage
