import styled from '@emotion/styled'
import React from 'react'

import { ListItem } from 'cuenect-web-core'
import { Heading, LineContainer, List, Paragraph } from './../components'
import { mq } from './../utility/breakpoint'

export const SupportPageDe = ({ origin }) => {
  return (
    <>
      <LineContainer className="LineContainer">
        <Heading type="h2" spacing={{ top: 0.5, bottom: 0.4 }}>
          Support
        </Heading>
      </LineContainer>
      <br />
      <br />
      <Heading type="h3" underlined>
        Livestream Page
      </Heading>
      <Heading type="h5">
        Was Sie tun können, wenn der Livestream nicht einwandfrei oder gar nicht
        läuft:
      </Heading>
      <List>
        <ListItem>
          Überprüfen Sie, ob ihr Browser auf dem neusten Stand ist und benutzen
          Sie, wenn möglich, Google Chrome oder Edge Chromium
        </ListItem>
        <ListItem>
          Deaktivieren Sie, falls vorhanden, ihren Ad- oder Content Blocker
        </ListItem>
        <ListItem>Deaktivieren Sie ihren VPN Zugang</ListItem>
        <ListItem>Laden Sie die Seite erneut</ListItem>
      </List>
      <Heading type="h3" underlined>
        Meeting Page
      </Heading>
      <Heading type="h5">Welchen Browser sollten Sie benutzen?</Heading>
      <Paragraph>
        Die Konferenz-Plattform ist nur mit den Browsern: Google Chrome und Edge
        Chromium erreichbar. Stellen Sie sicher, dass eine aktuelle Version
        dieser Browser installiert ist.
        <br />
        So können Sie Ihren Browser aktualisieren:
        <br />
        <br />
        Google Chrome: Klicken Sie auf das Einstellungs-Icon (3 Punkte) in der
        oberen rechen Ecke ihres Browsers. Wählen Sie den Menüpunkt{' '}
        <strong>Hilfe</strong> und anschließend{' '}
        <strong>Über Google Chrome</strong>. Sie können nun die Aktualisierung
        abschließen. <br />
        <br />
        Microsoft Edge: Klicken Sie auf das Einstellungs-Icon (3 Punkte) in der
        oberen rechen Ecke ihres Browsers. Wählen Sie den Menüpunkt{' '}
        <strong>Einstellungen</strong> sowie{' '}
        <strong>Infos zu Microsoft Edge</strong>. Sie können nun die
        Aktualisierung abschließen.
      </Paragraph>
      <Heading type="h5">So erhalten Sie die bestmögliche Verbindung:</Heading>
      <Paragraph>
        Die beste Verbindung bietet eine LAN-Verbindung. Wählen Sie, wenn
        möglich, eine Verbindung aus dem Homeoffice/Remote Work, da es aufgrund
        technischer Regulierungen innerhalb der Firmennetzwerke zu Problemen
        kommen kann. Falls Ihnen nur ein Firmennetzwerk zur Verfügung steht,
        nutzen Sie das Gäste-LAN (notfalls Gäste WLAN).
      </Paragraph>
      <Heading type="h5">
        Welche Up- und Downloadgeschwindigkeit ist erforderlich?
      </Heading>
      <Paragraph>
        Für einen störungsfreien Ablauf der Session ist eine Up- und
        Downloadgeschwindigkeit von mindestens 5Mbit erforderlich.
      </Paragraph>
      <Heading type="h5">
        Sie können die anderen Teilnehmer*innen nicht hören?
      </Heading>
      <Paragraph>
        Stellen Sie sicher, dass die Lautstärke Ihres Computers nicht zu leise
        eingestellt oder deaktiviert ist. Während einer Session können Sie zudem
        am unteren Bildschirmrand über das mittig platzierte Mikrofonsymbol
        kontrollieren, ob Sie den richtigen Lautsprecher ausgewählt haben. Durch
        einen Klick auf das Einstellungs-Icon (3 Punkte) innerhalb der einzelnen
        Kacheln anderer Teilnehmer*innen haben Sie weiterhin die Möglichkeit,
        die Lautstärke einzelner Personen anzupassen.
      </Paragraph>
      <Heading type="h5">
        Die anderen Teilnehmer*innen können Sie nicht hören?
      </Heading>
      <Paragraph>
        Überprüfen Sie, ob Sie ihrem Browser den Zugriff auf das Mikrofon
        erlaubt haben. Klicken Sie hierfür auf das Schloss-Icon in der
        Adresszeile und wählen Sie neben dem Mikrofon-Icon den Menüpunkt
        Zulassen. Sollten Sie die anderen Teilnehmer*innen noch immer nicht
        hören, dann klicken Sie auf das am unteren Bildschirmrand mittig
        platzierte Mikrofonsymbol und wählen das richtige Mikrofon aus. Stellen
        sie zudem sicher, dass Ihr Mikrofon funktioniert. Sollte auch dies nicht
        funktionieren, dann verwenden Sie ein externes Mikrofon (Bsp. Headset
        mit Kabelverbindung).
      </Paragraph>
      <Paragraph>
        Eine ausführlichere Anleitung finden Sie hier:{' '}
        <a
          href={`${origin}/pdf/Prejoin_DE.pdf`}
          target="_blank"
          className="url"
        >
          Mikrofon einrichten (S.2)
        </a>
      </Paragraph>
      <Heading type="h5">
        Die anderen Teilnehmer*innen können Sie nicht sehen?
      </Heading>
      <Paragraph>
        Prüfen Sie, ob Sie Ihrem Browser den Zugriff auf Ihre Webcam erlaubt
        haben. Klicken Sie hierfür auf das Schloss-Icon in der Adresszeile und
        wählen Sie neben dem Kamera-Icon den Menüpunkt Zulassen. Sollten Sie die
        anderen Teilnehmer*innen noch immer nicht sehen, dann klicken Sie auf
        das am unteren Bildschirmrand mittig platzierte Kamerasymbol und wählen
        Sie die richtige Webcam aus.
      </Paragraph>
      <Paragraph>
        Wenn Sie weiterhin Probleme mit der Nutzung der Webcam haben, finden Sie
        hier eine ausführlichere Anleitung:{' '}
        <a
          href={`${origin}/pdf/Prejoin_DE.pdf`}
          target="_blank"
          className="url"
        >
          Kamera einrichten (S.3)
        </a>
      </Paragraph>
      <Heading type="h5">So können Sie Ihren Bildschirm teilen:</Heading>
      <Paragraph>
        Klicken Sie hierfür auf das Bildschirm-Icon in der linken unteren Ecke
        Ihres Bildschirmrandes. In dem nun erscheinenden Popup-Fenster können
        Sie die Inhalte auswählen, die Sie mit den anderen Teilnehmer*innen
        teilen möchten und zudem den Audiokanal Ihres Computers freigeben.
      </Paragraph>
      <Heading type="h5">
        So können Sie einstellen, welche Teilnehmer*innen für sie sichtbar sind:
      </Heading>
      <Paragraph>
        Sie möchten eine einzelne Person sehen?
        <br />
        Dann klicken Sie auf die Kachel der gewünschten Teilnehmerin oder des
        gewünschten Teilnehmers. Sie sehen nun lediglich die ausgewählte Person.
        <br />
        Sie möchten alle Teilnehmer*innen sehen?
        <br />
        Klicken Sie am unteren rechten Bildschirmrand auf das Icon mit den vier
        Quadraten. Sie sehen nun alle Teilnehmer*innen.
      </Paragraph>
      <Heading type="h5">
        So können Sie mit anderen Teilnehmer*innen während einer Session
        chatten:
      </Heading>
      <Paragraph>
        Klicken Sie auf das Textfenstersymbol in der linken unteren Ecke Ihres
        Bildschirms und öffnen Sie so das Chat-Fenster. Sie können sich nun mit
        anderen Teilnehmer*innen austauschen.
      </Paragraph>

      <Heading type="h5">
        Sie werden beim Öffnen des Meetings über die Jisti App nach einem
        Passwort gefragt?
      </Heading>
      <Paragraph>
        Geben Sie den Meeting-link in den Browser Ihres Handys ein. Sie werden
        nun gefragt, ob Sie das Meeting über die App öffnen möchten. Bestätigen
        Sie dies.
      </Paragraph>
    </>
  )
}
export const SupportPageEn = ({ origin }) => {
  return (
    <>
      <LineContainer className="LineContainer">
        <Heading type="h2" spacing={{ top: 0.5, bottom: 0.4 }}>
          Support
        </Heading>
      </LineContainer>
      <br />
      <br />
      <Heading type="h3" underlined>
        Livestream Site
      </Heading>
      <Heading type="h5">
        What you can do if the live stream is not working properly or not at
        all:
      </Heading>
      <List>
        <ListItem>
          if possible: use Google Chrome or Edge Chromium and make sure your
          browser runs with the latest version
        </ListItem>
        <ListItem>Deactivate your ad or content blocker, if available</ListItem>
        <ListItem>Deactivate your VPN access</ListItem>
        <ListItem>Reload the page</ListItem>
      </List>
      <Heading type="h3" underlined>
        Meeting Site
      </Heading>
      <Heading type="h5">Which browser should you use?</Heading>
      <Paragraph>
        The conference platform is <strong>only</strong> accessible with the
        browsers: Google Chrome and Edge Chromium. Make sure you already
        installed the latest version of these browsers.
      </Paragraph>
      <Paragraph>
        How you can update your browser:
        <br />
        <br />
        Google Chrome: Click on the settings icon (3 dots) in the upper right
        corner of your browser. Select the menu tab: <strong>help</strong> and
        further: <strong>About Google Chrome</strong>. Now you can complete your
        update.
      </Paragraph>{' '}
      <Paragraph>
        Microsoft Edge: Click on the settings icon (3 dots) in the upper right
        corner of your browser. Select the menu tab: <strong>settings</strong>{' '}
        and further:
        <strong>Information about Microsoft Edge</strong>. Now you can complete
        your update.
      </Paragraph>
      <Heading type="h5">How to get the best possible connection:</Heading>
      <Paragraph>
        To get the best possible connection for your session please use a LAN
        connection. If possible, choose a connection from your home office /
        remote work, since technical regulations within the company networks can
        lead to problems. If you only have one company network, use the guest
        LAN (if necessary, guest WLAN).
      </Paragraph>
      <Heading type="h5">
        What is the required upload and download speed?
      </Heading>
      <Paragraph>
        An upload and download speed of at least 5Mbit is required for the
        session to run smoothly.
      </Paragraph>
      <Heading type="h5">You cannot hear the other participants?</Heading>
      <Paragraph>
        Make sure your computer's volume is not turned up too low or is turned
        off. During a session, you can use the microphone symbol at the bottom
        of the screen to check whether you have selected the correct speaker.
        Furthermore you can click on the settings icon (3 dots) within the
        individual selection field of other participants in order to adjust the
        volume of individual people.
      </Paragraph>
      <Heading type="h5">The other participants can’t hear you?</Heading>
      <Paragraph>
        Check whether you have allowed your browser to access the microphone.
        Therefor, click on the lock icon in the address line and select the menu
        tab: <strong>allow</strong> next to the microphone icon. If you still
        cannot hear the other participants, click on the microphone symbol at
        the bottom of the screen and select the correct microphone. Also make
        sure your microphone is working. If this does not work either, use an
        external microphone (e.g. headset with cable connection).
      </Paragraph>
      <Paragraph>
        You can find more detailed instructions here:{' '}
        <a
          href={`${origin}/pdf/Prejoin_EN.pdf`}
          target="_blank"
          className="url"
        >
          Set up microphone (p. 2)
        </a>
      </Paragraph>
      <Heading type="h5">You can't see the other participants?</Heading>
      <Paragraph>
        Check you have allowed your browser to access your webcam. Therefor,
        click on the lock icon in the address line and select the menu tab:{' '}
        <strong>allow</strong> next to the camera icon. If you still cannot see
        the other participants, click on the camera symbol at the bottom of the
        screen and select the correct webcam.
      </Paragraph>
      <Paragraph>
        If you still have problems using the webcam, you find more detailed
        instructions here:{' '}
        <a
          href={`${origin}/pdf/Prejoin_EN.pdf`}
          target="_blank"
          className="url"
        >
          Set up camera (p. 3)
        </a>
      </Paragraph>
      <Heading type="h5">How do I share my screen?</Heading>
      <Paragraph>
        Click on the screen icon in the lower left corner of your screen. Within
        the now appearing popup window you can select the content you want to
        share with other and furthermore share the audio channel on your
        computer.
      </Paragraph>
      <Heading type="h5">
        This is how you can set which participants are visible to you:
      </Heading>
      <Paragraph>
        Would you like to see a single person?
        <br />
        Directly click on the selection field of the participant you want to
        see: You now only see the selected person.
        <br />
        <br />
        Would you like to see all participants?
        <br />
        Click on the icon with the four squares in the lower right corner of the
        screen. You can now see all participants.
      </Paragraph>
      <Heading type="h5">
        How you can chat with other participants during a session:
      </Heading>
      <Paragraph>
        Click on the text field icon in the lower left corner of your screen to
        open the chat window. You can now exchange ideas with other
        participants.
      </Paragraph>
      <Heading type="h5">
        Are you asked for a password when opening the meeting via the Jisti app?
      </Heading>
      <Paragraph>
        Enter the meeting link in your mobile phone browser. You will now be
        asked whether you want to open the meeting via the app. Confirm.{' '}
      </Paragraph>
    </>
  )
}

const StartTeaser = styled.div<StartTeaserProps>(
  ({ theme: { grid }, fullheight, width }) => `
  height: 100%;
  ${[mq[3]]}{
    ${width && ` width: ${width}%;`}
  }
  ${
    fullheight &&
    `min-height:calc(100vh - 220px);
    ${[mq[3]]}{
      display:flex;
      align-items:center;
    }`
  }
  margin-top:120px;
  margin-left:${100 / grid.columns}vw;
  h1{
    margin:0;
  }
  p, .LineContainer{ margin-bottom:50px;}
`
)
